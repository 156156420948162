iframe {
    display: none;
}

.simple-form .MuiFilledInput-root {
    min-width: 400px;    
}
.simple-form .MuiFormControl-fullWidth {
    width: 400px;    
}
@media (max-width:567px) {   
    .simple-form .MuiFilledInput-root {
        min-width: 300px;
    }
    .simple-form .MuiFormControl-fullWidth {
        width: 300px;    
    }
}
.simple-form .ql-container *{
    font-size: 12px;
}
.simple-form .ql-container p{
    margin-bottom: 2px !important;
}
.MuiFormControl-root .MuiTypography-body2 {
    font-size: 12px;
}
.MuiFormControl-root .MuiTypography-body2 p {
    margin-top: 2px;
    margin-bottom: 2px;
}
.MuiFormControl-root .MuiTypography-body2{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.MuiFormControl-root .MuiTypography-body2 .item-wrapper .text-bold{
    font-weight: bold;
}
.user-profile {
    border-radius: 50%;
}
.ra-input-projects_assigned {
    max-width: 500px;
}
.project-assign-wrapper {
    width: 100% !important; /*to overwrite inline css*/
}
.project-assign-wrapper .MuiFormControl-fullWidth{
    width: 100%;
}